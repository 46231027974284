import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { LearningRepository } from '../repositories/learning.repository';

@Injectable({
  providedIn: 'any',
})
export class MarkLearningLessonAsCompletedUseCase
  implements UseCase<{ courseId: string; lessonId: string }, Observable<boolean>>
{
  constructor(private _repository: LearningRepository) {}

  execute(params: { courseId: string; lessonId: string }): Observable<boolean> {
    return this._repository.markLessonAsCompleted(params.courseId, params.lessonId);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { LearningCourse } from '../domain/learning.model';
import { LearningRepository } from '../repositories/learning.repository';

@Injectable({
  providedIn: 'any',
})
export class GetLearningCourseUseCase
  implements UseCase<string, Observable<LearningCourse | undefined>>
{
  constructor(private _repository: LearningRepository) {}

  execute(courseId: string): Observable<LearningCourse | undefined> {
    return this._repository.getCourseById(courseId);
  }
}

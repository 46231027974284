<app-shared-stepper-indicator
  [currentUserStep$]="currentUserStep$"
  [allowedUserSteps]="allowedUserSteps"
></app-shared-stepper-indicator>
<div class="onboarding-questionnaire">
  <div class="onboarding-questionnaire__header">
    <p class="questionnaire-text hedaing2--bold">
      لجعل تجربتك أفضل مع تاجر، لدينا بعض الأسئلة قبل أن تبدأ.
    </p>
    <p class="questionnaire-text body1--regular">
      سيساعدنا هذا في تخصيص تجربتك ومساعدتك بالمواد ذات الصلة وإرشادك لتصبح بائعًا عاليًا مع تاجر.
    </p>
  </div>
  <div *ngIf="zeroQuestionAnswered" class="onboarding-questionnaire__questions">
    <p class="questionnaire-text heading1--extra-bold">
      س١- ما المنصة الأساسية الذي تفضل استخدامها لوضع إعلاناتك؟
    </p>
    <div class="onboarding-questionnaire__questions__cards-container">
      <button
        (click)="firstQuestionAnswered()"
        class="onboarding-questionnaire__questions__card"
        *ngFor="let card of cardsMockData"
      >
        <img class="onboarding-questionnaire__questions__card__image" src="{{ card.image }}" />
        <p class="questionnaire-text body1--medium">{{ card.text }}</p>
      </button>
    </div>
  </div>
  <div *ngIf="isFirstQuestionAnswered" class="onboarding-questionnaire__questions">
    <p class="questionnaire-text heading1--extra-bold">
      س٢- ما المنصة الأساسية الذي تفضل بناء موقع الويب الخاص بك عليه؟
    </p>
    <div class="onboarding-questionnaire__questions__cards-container">
      <button
        (click)="secondQuestionAnswered()"
        class="onboarding-questionnaire__questions__card"
        *ngFor="let card of cardsMockData2"
      >
        <img class="onboarding-questionnaire__questions__card__image" src="{{ card.image }}" />
        <p class="questionnaire-text body1--medium">{{ card.text }}</p>
      </button>
    </div>
  </div>
  <div *ngIf="isSecondQuestionAnswered" class="onboarding-questionnaire__questions">
    <p class="questionnaire-text heading1--extra-bold">
      س ٣- ما هي الأسواق التي ترغب في العمل فيها؟
    </p>
    <div class="onboarding-questionnaire__questions__cards-container">
      <button
        (click)="thirdQuestionAnswered()"
        class="onboarding-questionnaire__questions__card"
        *ngFor="let card of cardsMockData3"
      >
        <img class="onboarding-questionnaire__questions__card__image" src="{{ card.image }}" />
        <p class="questionnaire-text body1--medium">{{ card.text }}</p>
      </button>
    </div>
  </div>
</div>

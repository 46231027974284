import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, mergeMap, Observable, take } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { PlainLayoutService } from 'src/app/presentation/shared/services/plain-layout.service';
import { SharedOverlayService } from 'src/app/presentation/shared/services/shared-overlay.service';
import { GetLearningCourseUseCase } from '../../../../core/usecases/get-learning-course-usecase';
import { MarkLearningLessonAsCompletedUseCase } from '../../../../core/usecases/mark-learning-lesson-as-completed-usecase';
import { BaseComponent } from '../../../shared/components/BaseComponent';
import { TooltipDirective } from '../../../shared/directives/tooltip/tooltip.directive';
import { SharedProgressBarComponent } from '../../shared/shared-progress-bar/shared-progress-bar.component';
import { OnboardingStoryGuideStateManager } from '../../state-manager/onboarding-story-guide.state-manager';
import {
  SelectCurrentActiveUserStepIndex,
  SelectUserOnboardingCompletionStatus,
} from '../../state-manager/selectors/selector-names';
import { OnboardingViewCourseMapper } from './mapper/onboarding-view-course.mapper';
import { OnboardingViewCourseState } from './model/onboarding-view-course.state';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _cio: any;
declare const YT: any;

@Component({
  selector: 'app-onboarding-view-course',
  templateUrl: './onboarding-view-course.component.html',
  styleUrls: ['./onboarding-view-course.component.scss'],
  standalone: true,
  imports: [NgIf, TooltipDirective, SharedProgressBarComponent, NgFor, AsyncPipe, TranslateModule],
})
export class OnboardingViewCourseComponent extends BaseComponent implements OnInit {
  @ViewChild('lessonsContainerRightElement', { static: false, read: ViewContainerRef })
  public lessonsContainerRightElementRef: ViewContainerRef;

  @ViewChild('lessonsContainerLeftMilestoneElement', { static: false, read: ViewContainerRef })
  public lessonsContainerLeftMilestoneElementRef: ViewContainerRef;

  @ViewChild('lessonsContainerRightElementVideo', { static: false })
  private _lessonsContainerRightElementVideoRef: ElementRef<HTMLIFrameElement>;

  @ViewChild('playerOutletElement', { static: false })
  private _playerOutletElementRef: ElementRef<HTMLDivElement>;

  public assetsPath = '../../../../../assets/img/on-boarding/';

  public state: OnboardingViewCourseState;

  /**
   * Current active index
   */
  public currentActiveStepSelector$: Observable<number>;

  public tooltipComponentRef = 'OnboardingNextStepTooltipComponent';

  /**
   * Reference to the youtube player
   */
  private _youtubePlayerRef: any;

  /**
   * The previous state that the video is playing at
   */
  private _previousState?: number;

  constructor(
    private _route: ActivatedRoute,
    private _getLearningCourseUseCase: GetLearningCourseUseCase,
    private _viewCourseMapper: OnboardingViewCourseMapper,
    private _markLearningLessonAsCompletedUseCase: MarkLearningLessonAsCompletedUseCase,
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
    private _changeDetectorRef: ChangeDetectorRef,
    private _sharedOverlayService: SharedOverlayService,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _sanitizer: DomSanitizer,
    private _router: Router,
    private _plainLayoutService: PlainLayoutService,
    private _renderer2: Renderer2,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    super();
    this._plainLayoutService.setCloseButton(true);
  }

  ngOnInit(): void {
    if (window.innerWidth < 992) {
      this._router.navigate([this._appURLs.PRODUCTS_V2_URL]);
    } else {
      this._route.params
        .pipe(
          mergeMap((params) =>
            this._getLearningCourseUseCase
              .execute(params.courseId)
              .pipe(
                map((course) =>
                  this._viewCourseMapper.map(course!, this.state?.selectedLessonIndex),
                ),
              ),
          ),
        )
        .pipe(this.takeUntilIsDestroyed())
        .subscribe({
          next: (state) => {
            this.state = state;
            this.state.lessons = this.state.lessons.map((lesson) =>
              lesson.videoUrl.includes('youtube')
                ? {
                    ...lesson,
                    safeVideoUrl: this._sanitizer.bypassSecurityTrustResourceUrl(
                      `${lesson.videoUrl}`,
                    ),
                  }
                : lesson,
            );
            this.isVisible = true;
            const eventName = 'onboarding_learning_course_page_load';
            const payload = {
              course_id: state.id,
              course_name: state.name,
              course_is_completed: state.isCompleted,
            };
            this._logMixpanelEventUseCase.execute({ eventName, payload });
            if (typeof _cio !== 'undefined') {
              _cio.track(eventName, payload);
            }
            this._changeDetectorRef.detectChanges();
            this._previousState = undefined;
            this._optionallyInstantiateYoutubePlayer();
          },
        });
      this._initializeStateSelectors();
      this._listenForLastStep();
    }
  }

  private _initializeStateSelectors(): void {
    this.currentActiveStepSelector$ = this._onboardingStoryGuideStateManager.selectStatePiece(
      SelectCurrentActiveUserStepIndex,
    );
  }

  private _listenForLastStep(): void {
    if (!this._onboardingStoryGuideStateManager.managerIsInitialized()) {
      return;
    }
    combineLatest({
      currentStep: this.currentActiveStepSelector$,
      userHasCompletedOnboardingJourney: this._onboardingStoryGuideStateManager.selectStatePiece(
        SelectUserOnboardingCompletionStatus,
      ),
    })
      .pipe(
        this.takeUntilIsDestroyed(),
        filter(
          (observers) =>
            !observers.userHasCompletedOnboardingJourney && observers.currentStep === 8,
        ),
      )
      .pipe(
        mergeMap(() => this._sharedOverlayService.getOverlayType()),
        filter((activeOverlay) => ['lastOnboardingStep'].indexOf(activeOverlay) === -1),
      )
      .subscribe({ next: () => this._sharedOverlayService.setOverlayType('lastOnboardingStep') });
  }

  getSelectedLesson(): any {
    return this.state.lessons[this.state.selectedLessonIndex];
  }

  onLessonClick(index: number): void {
    this.state.selectedLessonIndex = index;
    const eventName = 'onboarding_learning_course_lesson_click';
    const payload = {
      course_id: this.state.id,
      course_name: this.state.name,
      lesson_id: this.state.lessons[index].id,
      lesson_description: this.state.lessons[index].description,
      lesson_is_completed: this.state.lessons[index].isCompleted,
    };
    this._logMixpanelEventUseCase.execute({ eventName, payload });
    if (typeof _cio !== 'undefined') {
      _cio.track(eventName, payload);
    }
    this._markLearningLessonAsCompletedUseCase
      .execute({ courseId: this.state.id, lessonId: this.getSelectedLesson().id })
      .pipe(take(1))
      .subscribe();
  }

  /**
   * So, each and everytime that the user has selected a different video to play,
   * we will be listening for these
   */
  private _optionallyInstantiateYoutubePlayer(): void {
    if (!this._youtubePlayerRef) {
      /**
       * We need to check if the player reference has already been instantiated,
       * and in this case, if it has not, then we will be injecting it.
       */
      if ((window as any).YT) {
        this._instantiatePlayerObject();
      } else {
        this._injectYoutubePlayer();
        (window as any).onYouTubeIframeAPIReady = (): void => {
          this._instantiatePlayerObject();
          this._youtubePlayerRef.h.allow =
            'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
          this._youtubePlayerRef.h.attributes.sandbox =
            'allow-same-origin allow-scripts allow-forms allow-popups allow-popups-to-escape-sandbox allow-presentation';
          document.getElementById('onboarding-view-courses-youtube-iframe')!.remove();
          this._renderer2.appendChild(
            this._playerOutletElementRef.nativeElement,
            this._youtubePlayerRef.h,
          );
        };
      }
    } else {
      /**
       * Well, we already have a reference, so all we need to do is simply update the URL
       */
      this._attachVideoURLToYoutubePlayer(this.getSelectedLesson().videoUrl);
    }
  }

  /**
   *
   * @param videoURL
   *
   * Common method to instantiate the youtube player object
   */
  private _instantiatePlayerObject(): void {
    this._youtubePlayerRef = new YT.Player('onboarding-view-courses-youtube-iframe', {
      width: '100%',
      height: '100%',
      events: {
        onStateChange: (ev: { data: number }): void => {
          /**
           * So, a user can transition in a number of events when playing a video. These
           * are:
           *
           * 1. Not started -> -1
           * 2. Paused -> 2
           * 3. Finished -> 0
           *
           * We need to simply check this playlist state, so that we decided on whether
           * to emit the event that the user is playing the vide or not.
           *
           * So, when do we emit the event?
           *
           * 1. When the video has not yet been played i.e it is not yet in the entry
           * 2. When the video finished playing and the user wants to play it again -> 0
           *
           * So, we don't need to log resume playing
           */
          if (
            ev.data === YT.PlayerState.PLAYING &&
            this._previousState !== YT.PlayerState.PLAYING
          ) {
            this._onVideoPlay();
            this._previousState = YT.PlayerState.PLAYING;
          }

          if (ev.data === YT.PlayerState.ENDED) {
            /**
             * The video finished playing, so we update it's state
             */
            this._previousState = YT.PlayerState.ENDED;
          }
        },
        onReady: () => this._attachVideoURLToYoutubePlayer(this.getSelectedLesson().videoUrl),
      },
    });
  }

  /**
   *
   * @param videoUrl
   *
   * Attach either a new video URL that the user selects, or the initialized video URL
   */
  private _attachVideoURLToYoutubePlayer(videoUrl: string): void {
    this._youtubePlayerRef.loadVideoByUrl(`${videoUrl}?enablejsapi=1`, 0);
    this._youtubePlayerRef.stopVideo();
  }

  private _injectYoutubePlayer(): void {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);
  }

  private _onVideoPlay(): void {
    const index = this.state.selectedLessonIndex;
    const eventName = 'onboarding_learning_course_lesson_video_play';
    const payload = {
      course_id: this.state.id,
      course_name: this.state.name,
      lesson_id: this.state.lessons[index].id,
      lesson_description: this.state.lessons[index].description,
      lesson_is_completed: this.state.lessons[index].isCompleted,
    };
    this._logMixpanelEventUseCase.execute({ eventName, payload });
    if (typeof _cio !== 'undefined') {
      _cio.track(eventName, payload);
    }
  }
}

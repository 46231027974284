<div class="lessons-container" *ngIf="isVisible">
  <div class="lessons-container__right">
    <div
      class="lessons-container__right__body"
      #lessonsContainerRightElement
      appTooltip
      [appTooltipIsShown]="
        (currentActiveStepSelector$ | async) === 6 && lessonsContainerRightElementRef !== undefined
      "
      [appTooltipCaretConfig]="{
        hasCaret: true,
        height: 37,
        width: 37,
        positionOnOrigin: 'right',
        placementY: '40px',
        color: 'white',
        offsetFromHostElement: 20
      }"
      [appTooltipInjectionPayload]="{
        componentToInject: tooltipComponentRef,
        data: {
          currentStep: 6,
          uiContent: {
            title: {
              classes: 'heading2--bold',
              styling: {
                'column-gap': '8px'
              },
              sections: [
                {
                  text: 'ONBOARDING.STEPS.6.HEADER.a',
                  styling: {
                    color: '#0F1933'
                  }
                }
              ]
            },
            body: {
              paragraphs: []
            },
            footer: {
              classes: 'body2--medium',
              styling: {
                'justify-content': 'flex-end'
              },
              buttons: [
                {
                  action: 'next',
                  styling: {
                    padding: '16px 48px',
                    'column-gap': '8px'
                  },
                  sections: [
                    {
                      type: 'text',
                      content: 'ONBOARDING.STEPS.6.FOOTER.a'
                    },
                    {
                      type: 'image',
                      content: assetsPath + 'next-step-left-arrow.svg'
                    }
                  ]
                }
              ]
            }
          }
        }
      }"
      [appTooltipConnectedElement]="lessonsContainerRightElementRef"
      [appTooltipAttachedToElement]="lessonsContainerRightElement"
      [appTooltipStyling]="{
        'background-color': 'white',
        'border-radius': '10px'
      }"
      [appTooltipOverlayPosition]="{
        positionOnOrigin: 'left',
        placementY: '50px'
      }"
      [appTooltipDimensions]="{
        width: '502px'
      }"
      [appTooltipHasBackdrop]="true"
      [appTooltipParentCustomStyling]="{
        padding: 10
      }"
    >
      <div
        class="lessons-container__right__body__onboarding-view-courses-youtube-wrapper"
        #playerOutletElement
      >
        <div id="onboarding-view-courses-youtube-iframe"></div>
      </div>
      <p class="heading2--bold lessons-black-text">
        {{ 'ONBOARDING.ONBOARDING_COURSE.TITLE' | translate }} {{ getSelectedLesson().title }}
      </p>
    </div>
    <p class="lessons-container__right__course-description body1--bold lessons-black-text">
      {{ state.description }}
    </p>
  </div>
  <div class="lessons-container__left">
    <div class="lessons-container__left__header">
      <img src="{{ state.iconUrl }}" />
      <p class="heading1--bold lessons-black-text">
        {{ state.name }}
      </p>
    </div>

    <div class="lessons-container__left__progress">
      <div class="lessons-container__left__progress__header">
        <img src="{{ assetsPath + 'progress.svg' }}" />
        <p class="body1--bold lessons-black-text">
          {{ 'ONBOARDING.ONBOARDING_COURSE.PROGRESS.TITLE' | translate }}
        </p>
      </div>
      <app-shared-progress-bar [progressScore]="state.progressScore"></app-shared-progress-bar>
      <p class="lessons-black-text">
        {{ 'ONBOARDING.ONBOARDING_COURSE.PROGRESS.VALUE.a' | translate }} {{ state.progressText }}
        {{ 'ONBOARDING.ONBOARDING_COURSE.PROGRESS.VALUE.b' | translate }}
      </p>
    </div>
    <div
      class="lessons-container__left__milestone"
      #lessonsContainerLeftMilestoneElement
      appTooltip
      [appTooltipIsShown]="
        (currentActiveStepSelector$ | async) === 7 &&
        lessonsContainerLeftMilestoneElementRef !== undefined
      "
      [appTooltipCaretConfig]="{
        hasCaret: true,
        height: 37,
        width: 37,
        positionOnOrigin: 'left',
        placementY: '10px',
        color: 'white',
        offsetFromHostElement: 20
      }"
      [appTooltipInjectionPayload]="{
        componentToInject: tooltipComponentRef,
        data: {
          currentStep: 7,
          uiContent: {
            title: {
              classes: 'heading2--bold',
              styling: {
                'column-gap': '8px'
              },
              sections: [
                {
                  text: 'ONBOARDING.STEPS.7.HEADER.a',
                  styling: {
                    color: '#0F1933'
                  }
                }
              ]
            },
            body: {
              paragraphs: []
            },
            footer: {
              classes: 'body2--medium',
              styling: {
                'justify-content': 'flex-end'
              },
              buttons: [
                {
                  action: 'next',
                  styling: {
                    padding: '16px 48px',
                    'column-gap': '8px'
                  },
                  sections: [
                    {
                      type: 'text',
                      content: 'ONBOARDING.STEPS.7.FOOTER.a'
                    },
                    {
                      type: 'image',
                      content: assetsPath + 'next-step-left-arrow.svg'
                    }
                  ]
                }
              ]
            }
          }
        }
      }"
      [appTooltipConnectedElement]="lessonsContainerLeftMilestoneElementRef"
      [appTooltipAttachedToElement]="lessonsContainerLeftMilestoneElement"
      [appTooltipStyling]="{
        'background-color': 'white',
        'border-radius': '10px'
      }"
      [appTooltipOverlayPosition]="{
        positionOnOrigin: 'right',
        placementY: '5px'
      }"
      [appTooltipDimensions]="{
        width: '502px'
      }"
      [appTooltipHasBackdrop]="true"
      [appTooltipParentCustomStyling]="{
        padding: 10
      }"
    >
      <div class="lessons-container__left__progress__header">
        <img src="{{ assetsPath + 'flag.svg' }}" />
        <p class="body1--bold lessons-black-text">مراحل الهدف</p>
      </div>
      <div
        class="lessons-container__left__milestone__card"
        *ngFor="let lesson of state.lessons; index as index"
        (click)="onLessonClick(index)"
      >
        <img *ngIf="!lesson.isCompleted" src="{{ assetsPath + 'unchecked.svg' }}" />
        <img *ngIf="lesson.isCompleted" src="{{ assetsPath + 'check.svg' }}" />
        <p class="body2--regular lessons-black-text">
          {{ lesson.title }}
        </p>
      </div>
    </div>
  </div>
</div>

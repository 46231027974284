import { Injectable } from '@angular/core';
import { LearningCourse, LearningLesson } from '../../../../../core/domain/learning.model';
import { OnboardingViewCourseState } from '../model/onboarding-view-course.state';

const getCompletedLessonsCount = (lessons: any): number =>
  lessons.filter((lesson: any) => lesson.isCompleted).reduce((count: number) => count + 1, 0);

@Injectable({
  providedIn: 'any',
})
export class OnboardingViewCourseMapper {
  map(course: LearningCourse, selectedLessonIndex?: number): OnboardingViewCourseState {
    const lessons = course.lessons.map((lesson) => this.mapLesson(lesson));
    const lessonsCount = lessons.length;
    const completedLessonsCount = getCompletedLessonsCount(lessons);
    return {
      id: course.id,
      name: course.name,
      iconUrl: course.iconUrl,
      description: course.description,
      progressScore: (completedLessonsCount / lessonsCount) * 100,
      progressText: `${completedLessonsCount} من ${lessonsCount}`,
      selectedLessonIndex: selectedLessonIndex || 0,
      isCompleted: course.isCompleted,
      lessons,
    };
  }

  private mapLesson(lesson: LearningLesson): any {
    return {
      id: lesson.id,
      title: lesson.title,
      description: lesson.description,
      videoUrl: lesson.videoUrl,
      isCompleted: lesson.isCompleted,
    };
  }
}

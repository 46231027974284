import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { provideRouter } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PRODUCTS_V2_URL } from '../shared/constants';
import { OnboardingCurrentGoalProgressComponent } from './components/onboarding-current-goal-progress/onboarding-current-goal-progress.component';
import { OnboardingGettingStartedDialogComponent } from './components/onboarding-getting-started-dialog/onboarding-getting-started-dialog.component';
import { OnboardingGoalProgressCardComponent } from './components/onboarding-goal-progress-card/onboarding-goal-progress-card.component';
import { OnboardingLandingComponentComponent } from './components/onboarding-landing-component/onboarding-landing-component.component';
import { OnboardingLastStepDialogComponent } from './components/onboarding-last-step-dialog/onboarding-last-step-dialog.component';
import { OnboardingLearnWithUsComponent } from './components/onboarding-learn-with-us/onboarding-learn-with-us.component';
import { OnboardingViewCourseComponent } from './components/onboarding-view-course/onboarding-view-course.component';
import { OnboardingComponent } from './onboarding.component';
import { OnboardingStepDirective } from './shared/directives/onboarding-step/onboarding-step.directive';
import { OnboardingNextStepTooltipComponent } from './shared/onboarding-next-step-tooltip/onboarding-next-step-tooltip.component';
import { SharedProgressBarComponent } from './shared/shared-progress-bar/shared-progress-bar.component';

const SHARED_COMPONENTS = [SharedProgressBarComponent, OnboardingNextStepTooltipComponent];

const SHARED_DIRECTIVES = [OnboardingStepDirective];

const EXPORTABLE_COMPONENTS = [
  OnboardingCurrentGoalProgressComponent,
  OnboardingLearnWithUsComponent,
  OnboardingGoalProgressCardComponent,
  OnboardingLastStepDialogComponent,
];

const ROUTABLE_COMPONENTS = [OnboardingViewCourseComponent, OnboardingLandingComponentComponent];

@NgModule({
  exports: [...EXPORTABLE_COMPONENTS],
  imports: [
    MatProgressBarModule,
    CommonModule,
    TranslateModule,
    OnboardingComponent,
    OnboardingGettingStartedDialogComponent,
    ...ROUTABLE_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_COMPONENTS,
    ...EXPORTABLE_COMPONENTS,
  ],
  providers: [
    provideRouter([
      {
        path: '',
        redirectTo: PRODUCTS_V2_URL,
        pathMatch: 'full',
      },
      {
        path: 'learning/course/:courseId',
        component: OnboardingViewCourseComponent,
      },
    ]),
  ],
})
export class OnboardingModule {}

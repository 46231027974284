import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
// Ignoring the linting check because this comes from a Kotlin Library
import { NgFor, NgIf } from '@angular/common';
// @ts-ignore
import * as CountryResolver from '@taager-experience-shared/country-resolver';
import { BehaviorSubject } from 'rxjs';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import {
  CurrentUserStep,
  PossibleIndicatorSteps,
} from 'src/app/presentation/shared/components/shared-stepper-indicator/interfaces';
import { SharedOverlayService } from 'src/app/presentation/shared/services/shared-overlay.service';
import { resolveUserLandingUtility } from 'src/app/presentation/shared/utilities/resolve-user-landing.utility';
import { SharedStepperIndicatorComponent } from '../../../shared/components/shared-stepper-indicator/shared-stepper-indicator.component';

@Component({
  selector: 'app-onboarding-landing-component',
  templateUrl: './onboarding-landing-component.component.html',
  styleUrls: ['./onboarding-landing-component.component.scss'],
  standalone: true,
  imports: [SharedStepperIndicatorComponent, NgIf, NgFor],
})
export class OnboardingLandingComponentComponent {
  public currentUserStep$: BehaviorSubject<CurrentUserStep> = new BehaviorSubject<CurrentUserStep>(
    PossibleIndicatorSteps.onBoardingQuestion1,
  );

  public allowedUserSteps: Array<string>;

  public questionnaireAssetsPath = '../../../../../assets/img/on-boarding/';

  public zeroQuestionAnswered = true;

  public isFirstQuestionAnswered = false;

  public isSecondQuestionAnswered = false;

  public cardsMockData = [
    {
      text: 'تيك توك',
      image: `${this.questionnaireAssetsPath}tiktok.svg`,
    },
    {
      text: 'سناب شات',
      image: `${this.questionnaireAssetsPath}snap.svg`,
    },
    {
      text: 'فيسبوك',
      image: `${this.questionnaireAssetsPath}fb.svg`,
    },
    {
      text: 'مش متاكد',
      image: `${this.questionnaireAssetsPath}unknown.svg`,
    },
  ];

  public cardsMockData2 = [
    {
      text: 'wix',
      image: `${this.questionnaireAssetsPath}wix.svg`,
    },
    {
      text: 'woo',
      image: `${this.questionnaireAssetsPath}woo.svg`,
    },
    {
      text: 'الاثنين',
      image: `${this.questionnaireAssetsPath}wix-woo.svg`,
    },
    {
      text: 'مش متاكد',
      image: `${this.questionnaireAssetsPath}unknown.svg`,
    },
  ];

  public cardsMockData3 = [
    {
      text: 'مصر',
      image: `${this.questionnaireAssetsPath}egy.svg`,
    },
    {
      text: 'السعودية',
      image: `${this.questionnaireAssetsPath}ksa.svg`,
    },
    {
      text: 'الامارات',
      image: `${this.questionnaireAssetsPath}uae.svg`,
    },
    {
      text: 'الكل',
      image: `${this.questionnaireAssetsPath}all-countries.svg`,
    },
  ];

  constructor(
    private _router: Router,
    private _sharedOverlayService: SharedOverlayService,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    this.allowedUserSteps = ['onBoardingQuestion1', 'onBoardingQuestion2', 'onBoardingQuestion3'];
  }

  firstQuestionAnswered(): void {
    this.zeroQuestionAnswered = false;
    this.isFirstQuestionAnswered = true;
    this.currentUserStep$.next(PossibleIndicatorSteps.onBoardingQuestion2);
  }

  secondQuestionAnswered(): void {
    this.isSecondQuestionAnswered = true;
    this.zeroQuestionAnswered = false;
    this.isFirstQuestionAnswered = false;
    this.currentUserStep$.next(PossibleIndicatorSteps.onBoardingQuestion3);
  }

  thirdQuestionAnswered(): void {
    const isoCode2 = CountryResolver.getCountryFromIsoCode2(
      resolveUserLandingUtility().code.toUpperCase(),
    ).isoCode3;
    this._router.navigate([`${isoCode2}${this._appURLs.PRODUCTS_V2_URL}`]);
    if (window.innerWidth >= 992) {
      this._sharedOverlayService.setOverlayType('onboardingGettingStarted');
    }
  }
}
